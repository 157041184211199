import { History } from 'history'
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import isObject from 'lodash/isObject'
import isDate from 'lodash/isDate'

import {
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SHORT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  TIME_FORMAT_MOMENT,
  DATE_FORMAT_MOMENT_FULL,
  DATE_FORMAT_DOT,
  DATE_DATABASE_FORMAT,
  YEAR_FORMAT_MOMENT,
  MONTH_YEAR_FORMAT_MOMENT,
  MONTH_YEAR_FORMAT_SHORT_MOMENT,
  QUARTER_YEAR_FORMAT_SHORT_MOMENT,
} from '../constants/common'
import {
  workflowMappings,
  opsWorkflowToSalesforceFieldMapping,
  WorkflowTypes,
} from '@common/interfaces/notes'
import {
  DueDiligenceDocumentRequestStep,
  IDueDiligenceDocumentRequest,
  DUE_DILIGENCE_STATUS_SORT_ORDER,
} from '@common/interfaces/dueDiligence'

export const debounceEventHandler = (func: any, timeout: number) => {
  const debounced = debounce(func, timeout)

  return (...args: any[]) => debounced(...args)
}

export const transformNegativeToZero = (amount: any) => ((Number(amount) || 0) < 0 ? 0 : amount)

export const formatPrice = (price: any, decimals = 2) =>
  (Math.round(((Number(price) || 0) + Number.EPSILON) * 10000) / 10000).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

export const formatPercent = (percent: number, decimalPlaces = 2, scaleValue = 100) =>
  `${(percent * scaleValue || 0).toFixed(decimalPlaces)}%`

export const formatAmount = (amount: any, adormnemt = '') => {
  if (!amount) {
    return null
  }
  const num = Number(amount)
  const absNum = Math.abs(num)
  if (absNum < 1000) {
    return `${adormnemt}${num.toFixed(2)}`
  }
  if (absNum < 1000000) {
    return `${adormnemt}${(num / 1000).toFixed(1)}K`
  }
  if (absNum < 1000000000) {
    return `${adormnemt}${(num / 1000000).toFixed(2)}MM`
  }
  return `${adormnemt}${(num / 1000000000).toFixed(1)}B`
}

export const formatPriceNoDecimal = (price: any) =>
  (Math.round(((Number(price) || 0) + Number.EPSILON) * 10000) / 10000).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

export const formatPriceConditionalDecimal = (price: any) =>
  Math.abs(price) >= 100 || price === 0 ? formatPriceNoDecimal(price) : formatPrice(price)

export const formatNumber = (number: any, decimals = 0, forceDecimals = false) => {
  if (!number && number !== 0) {
    return null
  }
  return (Math.round(((Number(number) || 0) + Number.EPSILON) * 10000) / 10000).toLocaleString(
    'en-US',
    {
      minimumFractionDigits: forceDecimals ? decimals : 0,
      maximumFractionDigits: decimals,
    },
  )
}

export const unFormatPrice = (price: any) => {
  if (!price) {
    return 0
  }

  const parts = (1234.5)
    .toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .match(/(\D+)/g)
  let unFormatted = price.toString()

  unFormatted = unFormatted.split(parts[0]).join('')
  unFormatted = unFormatted.split(parts[1]).join('.')

  return parseFloat(unFormatted) || 0
}

const timeZoneDate = (date: string) => moment.utc(date)
export const formatDate = (date: string) => timeZoneDate(date).format(DATE_FORMAT_MOMENT)

export const formatDateShort = (date: string) => timeZoneDate(date).format(DATE_FORMAT_SHORT_MOMENT)

export const formatDateTime = (date: string) => moment(date).format(DATE_TIME_FORMAT_MOMENT)

export const formatTime = (date: string) => moment(date).format(TIME_FORMAT_MOMENT)

export const formatTextDate = (date: string) => timeZoneDate(date).format(DATE_FORMAT_MOMENT_FULL)

export const formatUnixDate = (date: number) => moment(date).format(DATE_FORMAT_DOT)

export const formatDateYear = (date: string) => timeZoneDate(date).format(YEAR_FORMAT_MOMENT)

export const formatDateMonthYear = (date: string) =>
  timeZoneDate(date).format(MONTH_YEAR_FORMAT_MOMENT)

export const formatDateMonthYearShort = (date: string) =>
  timeZoneDate(date).format(MONTH_YEAR_FORMAT_SHORT_MOMENT)

export const formatDateQuarterYearShort = (date: string) =>
  timeZoneDate(date).format(QUARTER_YEAR_FORMAT_SHORT_MOMENT)

export const databaseDateFormat = (date: string) => moment(date).format(DATE_DATABASE_FORMAT)

export const formatDateCalendar = (date: string) =>
  moment(date).calendar({
    sameDay: '[Today], h:mm a',
    lastDay: '[Yesterday], h:mm a',
    lastWeek: `[${moment()
      .startOf('day')
      .diff(moment(date).startOf('day'), 'days')} days ago], h:mm a`,
    nextDay: 'M/D/YY, h:mm a',
    nextWeek: 'M/D/YY, h:mm a',
    sameElse: 'M/D/YY, h:mm a',
  })

export const formatDateCalendarNoTime = (date: string, todayTime?: boolean) =>
  moment(date).calendar({
    sameDay: todayTime ? 'h:mm A' : '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: `[${moment().startOf('day').diff(moment(date).startOf('day'), 'days')} days ago]`,
    nextDay: 'M/D/YY',
    nextWeek: 'M/D/YY',
    sameElse: 'M/D/YY',
  })

export const formatTodayTimeOrDate = (date: string) =>
  moment(date).calendar({
    sameDay: TIME_FORMAT_MOMENT,
    lastDay: DATE_FORMAT_MOMENT,
    lastWeek: DATE_FORMAT_MOMENT,
    nextDay: DATE_FORMAT_MOMENT,
    nextWeek: DATE_FORMAT_MOMENT,
    sameElse: DATE_FORMAT_MOMENT,
  })

export const dateToString = (date: Date | string | null) => {
  if (!date) {
    return null
  }

  if (typeof date === 'string') {
    return date
  }

  if (isNaN(date?.getTime())) {
    return null
  }

  return [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ].join('-')
}

export const timeToString = (date: Date) =>
  [
    date.getUTCHours().toString().padStart(2, '0'),
    date.getUTCMinutes().toString().padStart(2, '0'),
    date.getUTCSeconds().toString().padStart(2, '0'),
  ].join(':')

export const dateTimeToString = (date: Date) => `${dateToString(date)}T${timeToString(date)}Z`

export const prepareRequestParams = (data: any) => {
  if (!isObject(data) || data instanceof FormData) {
    return data
  }

  const clonedData = cloneDeep(data)

  for (const key in data) {
    if (isObject(data[key])) {
      data[key] = prepareRequestParams(data[key])
    } else if (isDate(data[key])) {
      data[key] = dateToString(data[key])
    }
  }

  return clonedData
}

export const download = (blob: Blob, name: string) => {
  if (!document) return
  if (blob.type === 'application/json') {
    throw new Error('Download failed. Please contact your system administrator.')
  }

  const a = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const handleMultipleSelect =
  (
    handleSelectItems: (indexes: ((indexes: number[]) => number[]) | number[]) => void,
    handleSelectItem: (indexes: ((indexes: number) => number) | number) => void,
    activeIndexes?: number[],
  ) =>
  (event: any, index: number) => {
    const { ctrlKey, shiftKey, metaKey, target } = event
    const tagName = target.tagName.toUpperCase()
    if (metaKey && tagName === 'A') {
      return
    }
    event.preventDefault()
    if (
      (tagName === 'INPUT' ||
        target.classList.contains('MuiAutocomplete-option') ||
        target.closest('.react-select') ||
        target.closest('.MuiAutocomplete-paper') ||
        target.closest('.MuiPickersDay-root') ||
        target.closest('.MuiSvgIcon-root') ||
        target.closest('.MuiIconButton-root') ||
        target.closest('.MuiFormControl-root')) &&
      activeIndexes?.includes(index)
    ) {
      return
    }
    if (metaKey || ctrlKey) {
      handleSelectItems((indexes: number[]) =>
        indexes.includes(index)
          ? indexes.length === 1
            ? [index]
            : indexes.filter((item) => item !== index)
          : [...indexes, index],
      )
    } else if (shiftKey) {
      handleSelectItems((indexes) =>
        indexes.length
          ? Array(Math.max(indexes[indexes.length - 1], index) - Math.min(indexes[0], index) + 1)
              .fill(undefined)
              .map((_, idx) => Math.min(indexes[0], index) + idx)
              .filter(
                (index) =>
                  !!target.closest('table')?.querySelector(`.activableRow[data-index="${index}"]`),
              )
          : [index],
      )
    } else {
      handleSelectItems([index])
      handleSelectItem(index)
    }
  }

export const handleMultipleCellSelect =
  (
    handleSelectCells: (indexes: ((indexes: number[][]) => number[][]) | number[][]) => void,
    reset?: () => void,
  ) =>
  (event: any, rowIndex: number, columnIndex: number) => {
    event.preventDefault()
    const { ctrlKey, shiftKey, metaKey, target } = event
    const tagName = target.tagName.toUpperCase()
    if (
      tagName === 'INPUT' ||
      target.classList.contains('MuiAutocomplete-option') ||
      target.closest('.react-select') ||
      target.closest('.MuiAutocomplete-paper') ||
      target.closest('.MuiPickersDay-root') ||
      target.closest('.MuiSvgIcon-root') ||
      target.closest('.MuiIconButton-root') ||
      target.closest('.MuiFormControl-root')
    ) {
      return
    }
    if (metaKey || ctrlKey) {
      handleSelectCells((indexes: number[][]) => {
        const result = [...(indexes || [])]
        if (!result[rowIndex]) {
          result[rowIndex] = []
        }

        result[rowIndex] = result[rowIndex].includes(columnIndex)
          ? result[rowIndex].filter((item) => item !== columnIndex)
          : [...result[rowIndex], columnIndex]

        return result
      })
    } else if (shiftKey) {
      handleSelectCells((indexes: number[][]) => {
        const result = [...(indexes || [])]
        if (!result[rowIndex]) {
          result[rowIndex] = []
        }
        if (!result.flat().length) {
          result[rowIndex] = [columnIndex]
          return result
        }
        const minRowIndex = Math.min(
          result.findIndex((indexes) => !!indexes?.filter((index) => index !== undefined).length),
          rowIndex,
        )
        const maxRowIndex = Math.max(
          // @ts-ignore
          result.findLastIndex(
            (indexes: number[]) => !!indexes?.filter((index) => index !== undefined).length,
          ),
          rowIndex,
        )
        const minColumnIndex = Math.min(
          columnIndex,
          ...result.flat().filter((index) => index !== undefined),
        )
        const maxColumnIndex = Math.max(
          columnIndex,
          ...result.flat().filter((index) => index !== undefined),
        )

        for (let i = minRowIndex; i <= maxRowIndex; i++) {
          result[i] = Array(maxColumnIndex - minColumnIndex + 1)
            .fill(undefined)
            .map((_, idx) => minColumnIndex + idx)
            .filter(
              (columnIndex) =>
                !!target
                  .closest('table')
                  ?.querySelector(
                    `.activableRow[data-index="${i}"] .activableCell[data-index="${columnIndex}"]`,
                  ),
            )
        }

        return result
      })
    } else {
      const result = []
      result[rowIndex] = [columnIndex]
      handleSelectCells(result)
    }
    reset && reset()
  }

export const handleMultipleActivableSelect =
  (
    handleSelect: (indexes: ((indexes: number[]) => number[]) | number[]) => void,
    reset?: () => void,
    activableClass: string = 'activableRow',
  ) =>
  (event: any, index: number) => {
    event.preventDefault()
    const { ctrlKey, shiftKey, metaKey, target } = event

    if (metaKey || ctrlKey) {
      handleSelect((indexes: number[]) =>
        indexes.includes(index)
          ? indexes.length === 1
            ? [index]
            : indexes.filter((item) => item !== index)
          : [...indexes, index],
      )
    } else if (shiftKey) {
      handleSelect((indexes) =>
        indexes.length
          ? Array(Math.max(indexes[indexes.length - 1], index) - Math.min(indexes[0], index) + 1)
              .fill(undefined)
              .map((_, idx) => Math.min(indexes[0], index) + idx)
              .filter(
                (index) =>
                  !!target
                    .closest('table')
                    ?.querySelector(`.${activableClass}[data-index="${index}"]`),
              )
          : [index],
      )
    } else {
      handleSelect([index])
    }
    reset && reset()
  }

export const handleMultipleCheckboxSelect =
  (handleSelectItems: (indexes: ((indexes: number[]) => number[]) | number[]) => void) =>
  (event: any, index: number) => {
    const { shiftKey } = event
    if (shiftKey) {
      handleSelectItems((indexes) =>
        indexes.length
          ? Array(Math.max(indexes[indexes.length - 1], index) - Math.min(indexes[0], index) + 1)
              .fill(undefined)
              .map((_, idx) => Math.min(indexes[0], index) + idx)
          : [index],
      )
    } else {
      handleSelectItems((indexes: number[]) =>
        indexes.includes(index) ? indexes.filter((item) => item !== index) : [...indexes, index],
      )
    }
  }

export const currentWorkFlow = (
  pathname: string,
  isPortfolio?: boolean,
  isAdmin?: boolean,
  isBDO?: boolean,
): WorkflowTypes => {
  const path = pathname.split('/')
  if (workflowMappings?.[path[1]]) {
    return workflowMappings[path[1]]
  } else if (path[1] === '' && (isPortfolio || isAdmin || isBDO)) {
    return WorkflowTypes.homePage
  }
}

export const secondaryWorkFlow = (pathname: string): string => {
  const path = pathname.split('/')
  const lastEl = path[path.length - 1]
  if (opsWorkflowToSalesforceFieldMapping?.[lastEl]) {
    return opsWorkflowToSalesforceFieldMapping[lastEl]
  }
  return opsWorkflowToSalesforceFieldMapping.summary
}

export function loadingArrayReducer(prefixes: string[], itemsToIgnore: string[] = []) {
  const request = new RegExp(`^(?:${prefixes.join('|')}).+request$`, 'i')
  const successOrFailure = new RegExp(`^(?:${prefixes.join('|')}).+(?:success|failure)$`, 'i')
  let ignore: RegExp
  if (itemsToIgnore.length) {
    ignore = new RegExp(
      `${itemsToIgnore.map((item) => item.replace(/_(request|success|failure)/gi, '')).join('|')}`,
      'i',
    )
  }

  return (state: string[] = [], action: any) => {
    if (ignore && ignore.test(action.type)) {
      return state
    }
    if (action.params?.skipLoader) {
      return state
    }
    if (request.test(action.type)) {
      return [...state, action.type]
    } else if (successOrFailure.test(action.type)) {
      const type = action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST')
      return state.filter((item) => item !== type)
    }
    return state
  }
}
export const stringToColor = (string: string) => {
  let i

  let hash = 0

  for (i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  const color = '#' + (Math.abs(hash) % 0xffffff).toString(16)
  /* eslint-enable no-bitwise */

  return color
}

export const hexToRgbAOpaq = (hex: string) => {
  let c: any
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.6)'
  } else {
    return 'rgba(0,0,0,.6)'
  }
}

export const visibilityChangeEventHandler = (callback: (beacon?: boolean) => void) => {
  const handleVisibilityChange = async (event: any) => {
    if (document.visibilityState === 'hidden') {
      callback(true)
    }
  }
  document.addEventListener('visibilitychange', handleVisibilityChange, false)
  return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
}

export const historyChangeEventHandler = (
  callback: (event?: any) => void,
  history: History,
  pathname: string,
) => {
  const handleLocationChange = async (location: any) => {
    if (location.pathname !== pathname) {
      callback()
    }
  }
  const unlisten = history.listen(handleLocationChange)
  return () => {
    unlisten()
  }
}

export const handleStopPropagation = (event: any) => event.stopPropagation()

export const voidHandler = () => {}

export const formatDateRange = (
  dates: {
    startDate: string
    endDate: string
  }[],
): string => {
  if (!dates.length) {
    return ''
  }

  const format = (startDate: string, endDate: string): string => {
    const startDateMoment = moment(startDate)
    const endDateMoment = moment(endDate)
    return startDateMoment.isSame(endDateMoment, 'month')
      ? startDateMoment.format('MMM YY')
      : `${startDateMoment.format('MMM YY')}-${endDateMoment.format('MMM YY')}`
  }
  const formattedDates: string[] = []
  let count = 0

  dates.sort((a, b) => a.startDate.localeCompare(b.startDate))
  dates = dates.map(({ startDate, endDate }) => ({
    startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
    endDate: moment(endDate).endOf('month').format('YYYY-MM-DD'),
  }))

  let currentStart = dates[0].startDate
  let currentEnd = dates[0].endDate

  for (let i = 1; i < dates.length; i++) {
    const next = dates[i]

    if (moment(next.startDate).isAfter(moment(currentEnd).add(1, 'day'))) {
      count += Math.ceil(moment(currentEnd).diff(moment(currentStart), 'months', true))
      formattedDates.push(format(currentStart, currentEnd))
      currentStart = next.startDate
    }

    currentEnd = next.endDate > currentEnd ? next.endDate : currentEnd
  }

  count += Math.ceil(moment(currentEnd).diff(moment(currentStart), 'months', true))
  formattedDates.push(format(currentStart, currentEnd))

  return `${count} (${formattedDates.join(', ')})`
}

export const highlightsMatches = (string: any, find: string) =>
  find
    .split(' ')
    .map((word) => word.trim())
    .filter(Boolean)
    .map((word) => new RegExp(`(?![^<]*>)${word.replace('\\', '\\\\')}`, 'gi'))
    .reduce(
      (currentStr, regexPattern) =>
        currentStr?.replace(
          regexPattern,
          (match: string) => `<strong style="color:#0066f5">${match}</strong>`,
        ),
      string,
    )

export const sortAndGroupDueDiligenceDocumentsRequests = (
  documentRequests: IDueDiligenceDocumentRequest[],
) => {
  const documents = documentRequests.sort((a, b) => {
    if (a.type.isPriority && !b.type.isPriority) return -1
    if (!a.type.isPriority && b.type.isPriority) return 1

    if (a.type.isPriority < b.type.isPriority) return -1
    if (a.type.isPriority > b.type.isPriority) return 1

    return DUE_DILIGENCE_STATUS_SORT_ORDER[a.status] - DUE_DILIGENCE_STATUS_SORT_ORDER[b.status]
  })

  const samples = documents.filter((documentRequest) =>
    documentRequest.type.name.toLowerCase().includes('sample'),
  )
  const companyBackground = documents.filter(
    (documentRequest) =>
      !documentRequest.type.name.toLowerCase().includes('sample') &&
      documentRequest.type.step === DueDiligenceDocumentRequestStep.CompanyBackground,
  )
  const team = documents.filter(
    (documentRequest) =>
      !documentRequest.type.name.toLowerCase().includes('sample') &&
      documentRequest.type.step === DueDiligenceDocumentRequestStep.Team,
  )
  const financials = documents.filter(
    (documentRequest) =>
      !documentRequest.type.name.toLowerCase().includes('sample') &&
      documentRequest.type.step === DueDiligenceDocumentRequestStep.Financials,
  )

  return {
    companyBackground,
    team,
    financials,
    samples,
  }
}

export const formatValue = ({
  value,
  type,
  nullCondition,
}: {
  value: any
  type: string
  nullCondition?: boolean
}) => {
  if (nullCondition || !value) {
    return '-'
  }
  switch (type) {
    case 'date':
      return formatDate(value)
    case 'amount':
      return formatPrice(value)
    case 'percent':
      return formatPercent(value)
    default:
      return value
  }
}

export const createRequestTypes = (prefix: string, base: string) => {
  const types = ['REQUEST', 'SUCCESS', 'FAILURE']
  const prefixAndBase = `${prefix}/${base}`
  return types.map((type) => `${prefixAndBase}_${type}`) as [string, string, string]
}

export const getRGBFFromHex = (hex: string, opacity: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  )

export const formatMaxNumber = (value: number, max: number, hideNegative = true) => {
  if (value) {
    if (hideNegative && value < 0) {
      return '-'
    }
    if (value > max) {
      return `${max}+`
    }
    return value.toFixed(2)
  }
  return '-'
}

export const formatSeconds = (decimalSeconds: number): string => {
  const minutes = Math.floor(decimalSeconds / 60)
  const seconds = Math.floor(decimalSeconds % 60)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}
