import React, { useMemo, useCallback, useEffect } from 'react'
import { EntitySubType } from '@common/interfaces/entityInfo'
import Autocomplete from '../../../components/Common/Autocomplete'
import Box from '@mui/material/Box'
import cn from 'classnames'
import styles from '../CollectionsChecksPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ICheck } from '@common/interfaces/collection'
import TableRow from '../../../components/Common/TableRow'
import TableCell from '../../../components/Common/TableCell'
import { formatPrice } from '../../../helpers/helpers'
import CreatableSelectField, { IOptionType } from '../../../components/Common/CreatableSelectField'
import { MenuIcon } from '../../../components/Common/Icons'

export const ClientNameDropdown = ({
  autocompleteRef,
  check,
  handleUpdateCheck,
  index,
  value,
  name,
  clientsOptions,
}: {
  autocompleteRef?: React.RefObject<HTMLInputElement>
  check: ICheck
  handleUpdateCheck: (check: object) => void
  index: number
  value: any
  name: string
  clientsOptions: IOptionType[]
}) => {
  const handleUpdateClientName = useCallback(
    (event, value: any) => {
      handleUpdateCheck({
        id: check.id,
        clientName: typeof value === 'string' ? value : value?.value,
      })
    },
    [check, handleUpdateCheck],
  )

  return (
    <Autocomplete
      ref={autocompleteRef}
      clearIcon={null}
      label=""
      className={cn({
        focusableInput: true,
        [styles.validInput]: !!check.clientInfo,
        invalidInput: !check.clientInfo,
      })}
      name={`${name}.clientName`}
      placeholder="Select client"
      tabIndex={2 * index}
      value={value?.clientName || null}
      options={clientsOptions}
      getOptionValue={(option) => option.value}
      onChange={handleUpdateClientName}
    />
  )
}

export const EntityNameDropdown = ({
  entityNameRef,
  check,
  handleUpdateCheck,
  index,
  value,
  name,
  entityType = 'debtor',
  handleAddDebtor,
  loadDebtors,
}: {
  entityNameRef?: React.RefObject<HTMLInputElement>
  check: ICheck
  handleUpdateCheck: (check: object) => void
  index: number
  value: any
  name: string
  entityType?: string
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
}) => {
  const handleChangeEntity = useCallback(
    async (event, newValue: any) => {
      if (!newValue) {
        handleUpdateCheck({
          id: check.id,
          [entityType]: '',
        })
      } else if (newValue.id) {
        handleUpdateCheck({
          id: check.id,
          [entityType]: newValue?.value,
        })
      } else if (newValue.value) {
        const result = await handleAddDebtor(newValue.value, value.clientName)
        if (!!result?.error) {
          return
        }
        handleUpdateCheck({
          id: check.id,
          [entityType]: newValue?.value,
        })
      }
    },
    [check, handleAddDebtor, handleUpdateCheck, value, entityType],
  )

  const formName = useMemo(
    () => (entityType === 'debtor' ? 'checkAccount' : 'originalEntity'),
    [entityType],
  )

  return (
    <CreatableSelectField
      ref={entityNameRef}
      label=""
      name={`${name}.${formName}`}
      tabIndex={2 * index + 1}
      className={cn({
        focusableInput: true,
        [styles.validInput]: !!check.accountNumber && !!check.checkAccount?.linkedName,
        invalidInput: !check.accountNumber || !check.checkAccount?.linkedName,
      })}
      placeholder="Select customer"
      onAddValue={(debtorName) => handleAddDebtor(debtorName, value.clientName)}
      options={[]}
      getOptionValue={(option) => option.value}
      isAsync
      loadOptions={loadDebtors}
      onChangeCustom={handleChangeEntity}
    />
  )
}

interface IProps {
  check: ICheck
  index: number
  activeItems: number[]
  activeItem: number
  handleSelectRow: (event: React.MouseEvent, index: number) => void
  handleUpdateCheck: (check: object) => void
  value: any
  name: string
  clientsOptions: IOptionType[]
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
  handleClickMenu: (check: ICheck, event: React.MouseEvent<HTMLElement>) => void
}

const CheckRow = ({
  check,
  index,
  activeItems,
  activeItem,
  handleSelectRow,
  handleUpdateCheck,
  value,
  name,
  clientsOptions,
  loadDebtors,
  handleAddDebtor,
  handleClickMenu,
}: IProps) => {
  const isBillPaymentProvider = useMemo(
    () => check?.checkAccount?.entityInfo?.type === EntitySubType.BillPaymentProvider,

    [check],
  )
  const isValidRow = useMemo(
    () =>
      !!check?.clientInfo &&
      !!check?.accountNumber &&
      !!check?.checkAccount?.linkedName &&
      (!isBillPaymentProvider || !!check?.originalEntity),
    [check, isBillPaymentProvider],
  )

  const isActiveRow = useMemo(() => activeItems.includes(index), [activeItems, index])
  const [activeField, setActiveField] = React.useState<string | null>(null)

  const handleSelectCell = useCallback((event, field) => {
    setActiveField(field)
  }, [])

  const clientInputRef = React.useRef<HTMLInputElement>(null)
  const customerInputRef = React.useRef<HTMLInputElement>(null)
  const originalEntityInputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isActiveRow) {
      if (activeField === `${name}.clientName`) {
        clientInputRef.current?.focus()
      } else if (activeField === `${name}.checkAccount`) {
        customerInputRef.current?.focus()
      } else if (activeField === `${name}.originalEntity`) {
        originalEntityInputRef.current?.focus()
      }
    }
  }, [isActiveRow, activeField, name])

  if (!check) {
    return null
  }

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={`check-list-row-${check.id}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: activeItem === index,
        [styles.validRow]: isValidRow,
      })}
      onClick={(event) => handleSelectRow(event, index)}
    >
      <TableCell className={genericSs.tableTextRight}>{check.checkNumber}</TableCell>
      <TableCell
        className={genericSs.tableTextLeft}
        onClick={(event) => handleSelectCell(event, `${name}.clientName`)}
      >
        {isActiveRow ? (
          <ClientNameDropdown
            autocompleteRef={clientInputRef}
            check={check}
            handleUpdateCheck={handleUpdateCheck}
            index={index}
            value={value}
            name={name}
            clientsOptions={clientsOptions}
          />
        ) : (
          check.clientName || <div className={styles.selectCell}>Select client</div>
        )}
      </TableCell>
      <TableCell
        className={genericSs.tableTextLeft}
        onClick={(event) => handleSelectCell(event, `${name}.checkAccount`)}
      >
        {isActiveRow ? (
          <EntityNameDropdown
            entityNameRef={customerInputRef}
            check={check}
            handleUpdateCheck={handleUpdateCheck}
            index={index}
            value={value}
            name={name}
            entityType="debtor"
            handleAddDebtor={handleAddDebtor}
            loadDebtors={loadDebtors}
          />
        ) : (
          check.checkAccount?.linkedName || <div className={styles.selectCell}>Select customer</div>
        )}
      </TableCell>
      <TableCell
        className={genericSs.tableTextLeft}
        onClick={(event) => handleSelectCell(event, `${name}.originalEntity`)}
      >
        {isBillPaymentProvider && isActiveRow ? (
          <EntityNameDropdown
            entityNameRef={originalEntityInputRef}
            check={check}
            handleUpdateCheck={handleUpdateCheck}
            index={index}
            value={value}
            name={name}
            entityType="originalEntity"
            handleAddDebtor={handleAddDebtor}
            loadDebtors={loadDebtors}
          />
        ) : isBillPaymentProvider ? (
          check.originalEntity || <div className={styles.selectCell}>Select original customer</div>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {formatPrice(check.paymentAmount)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="inline-box" ml={1}>
          <MenuIcon onClick={(event) => handleClickMenu(check, event)} size="small" />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default CheckRow
