import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './NewsContainer.module.scss'
import { ROUTES } from '../../constants/routes'
import LinkButton from '@mui/material/Link'
import { Link, generatePath } from 'react-router-dom'

import { INewsfeedData } from '@common/interfaces/newsfeed'
import { WorkflowTypes } from '@common/interfaces/notes'
import { ILoadingData } from '../../redux/types'
import NewsItem from './NewsItem'
import NewsItemLoader from './NewsItemLoader'
import { ReactComponent as NoNews } from '@assets/images/no-news.svg'
import { PER_PAGE } from '@common/constants/filters'

interface IProps {
  workflow: WorkflowTypes
  newsfeedData: ILoadingData<INewsfeedData>
  listNewsfeed: (data: object) => void
}

const NewsContainer = ({ workflow, newsfeedData, listNewsfeed }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { data: newsfeed, totalItems } = useMemo(
    () => ({
      data: newsfeedData?.data?.data,
      isLoading: newsfeedData?.isLoading,
      totalItems: newsfeedData?.data?.totals?.totalItems,
    }),
    [newsfeedData],
  )

  const handleListNewsfeed = useCallback(
    (params?: object) => {
      if (
        id &&
        [
          WorkflowTypes.homePage,
          WorkflowTypes.entityPage,
          WorkflowTypes.clientPage,
          WorkflowTypes.clientManagement,
        ].includes(workflow)
      ) {
        listNewsfeed({
          [workflow === WorkflowTypes.entityPage ? 'entityInfoId' : 'clientInfoId']: id,
          perPage: PER_PAGE,
          ...params,
        })
      } else {
        listNewsfeed({
          perPage: PER_PAGE,
          ...params,
        })
      }
    },
    [id, workflow, listNewsfeed],
  )

  useEffect(() => {
    handleListNewsfeed()
  }, [handleListNewsfeed])

  const loadMore = useCallback(() => {
    handleListNewsfeed({
      loadMore: true,
      page: Math.ceil(newsfeedData.data.data.length / PER_PAGE),
    })
  }, [handleListNewsfeed, newsfeedData])

  return (
    <>
      {newsfeed?.length ? (
        <div className={styles.newsContainer}>
          <LinkButton
            className={styles.editTitle}
            component={Link}
            to={generatePath(ROUTES.MARKET_NEWS)}
          >
            Edit
          </LinkButton>
          <div className={styles.container} id="newsfeedContainer">
            <InfiniteScroll
              dataLength={newsfeed?.length}
              className={styles.infiniteScroll}
              next={loadMore}
              hasMore={newsfeed?.length < totalItems}
              loader={<NewsItemLoader />}
              scrollableTarget="newsfeedContainer"
            >
              {newsfeed?.map((item) => (
                <NewsItem key={item.id} item={item} />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className={styles.noNews}>
          <NoNews />
          <p>Check back later for industry news!</p>
        </div>
      )}
    </>
  )
}

export default NewsContainer
