import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { updateNewsfeed, showNewsfeed } from '../../redux/newsfeed/actions'
import { newsfeedItem } from '../../redux/newsfeed/selectors'
import MarketNewsDetails from './MarketNewsDetails'
import { listBrandOptions } from '../../redux/newsfeed/actions'

const actions = {
  updateNewsfeed,
  showNewsfeed,
  listBrandOptions,
}

const selector = createStructuredSelector({
  newsfeedItem,
})

export default connect(selector, actions)(MarketNewsDetails)
