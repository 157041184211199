import React, { useMemo } from 'react'
import { INewsfeed } from '@common/interfaces/newsfeed'
import LinkUrl from '@mui/material/Link'

import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  newsItem: INewsfeed
  type: string
}

const LinkedAccountItem = ({ newsItem, type }: IProps) => {
  const { isBDO } = usePermissions()

  const typeEntity = useMemo(() => {
    return type === 'brand' ? 'brandFromSalesforce' : 'brandFromReferralSource'
  }, [type])

  const salesforceLink = useMemo(() => {
    const salesforceType = newsItem?.[typeEntity]?.salesforceType
    const salesforceId = newsItem?.[typeEntity]?.salesforceId
    if (!salesforceType || !salesforceId) {
      return null
    }
    return `${newsItem?.salesforceBaseUrl}${salesforceType}/${salesforceId}/view`
  }, [newsItem, typeEntity])
  return (
    <>
      {isBDO ? (
        <LinkUrl color="primary" href={salesforceLink} target="_blank" rel="noopener noreferrer">
          {newsItem[typeEntity]?.brandName}
        </LinkUrl>
      ) : newsItem?.[typeEntity] ? (
        newsItem[typeEntity]?.entityInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.ENTITY_PAGE, {
              id: newsItem[typeEntity].entityInfo.id,
            })}
          >
            {newsItem[typeEntity]?.brandName}
          </LinkButton>
        ) : newsItem[typeEntity]?.clientInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.CLIENT_PAGE, {
              id: newsItem[typeEntity].clientInfo.id,
            })}
          >
            {newsItem[typeEntity]?.brandName}
          </LinkButton>
        ) : newsItem[typeEntity]?.salesforceProspectInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.PROSPECT_PAGE, {
              id: newsItem[typeEntity].salesforceProspectInfo.id,
            })}
          >
            {newsItem[typeEntity]?.brandName}
          </LinkButton>
        ) : (
          <LinkUrl color="primary" href={salesforceLink} target="_blank" rel="noopener noreferrer">
            {newsItem[typeEntity]?.brandName}
          </LinkUrl>
        )
      ) : null}
    </>
  )
}

export default LinkedAccountItem
