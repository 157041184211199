import React from 'react'

import styles from './MarketNewsTable.module.scss'

import { INewsfeed, NewsTags, TAG_COLOR_MAPPING } from '@common/interfaces/newsfeed'
import Link from '@mui/material/Link'
import { formatDateCalendarNoTime } from '../../helpers/helpers'
import Card from '../Common/Card'
import LinkedAccountItem from '../MarketNewsDetails/LinkedAccountItem'
import cn from 'classnames'

interface IProps {
  item: INewsfeed
  isCurrentItem: boolean
}

const MarketNewsDetails = ({ item, isCurrentItem }: IProps) => {
  return (
    <Card className={cn(styles.item, { [styles.isCurrentItem]: isCurrentItem })} noHeaderMargin>
      <div className={styles.titleContainer}>
        <div className={styles.tagsContainer}>
          <div style={TAG_COLOR_MAPPING[item.type]} className={styles.itemDescription}>
            {item.type}
          </div>
          {item.isClient && (
            <div style={TAG_COLOR_MAPPING[NewsTags.Client]} className={styles.itemDescription}>
              {NewsTags.Client}
            </div>
          )}
          {item.isProspect && (
            <div style={TAG_COLOR_MAPPING[NewsTags.Prospect]} className={styles.itemDescription}>
              {NewsTags.Prospect}
            </div>
          )}
        </div>
        <div className={styles.itemDate}>{formatDateCalendarNoTime(item?.createdAt)}</div>
      </div>

      <div className={styles.itemSubtitles}>
        {item.link && (
          <Link
            className={styles.itemLink}
            color="primary"
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.headline || ''}
          </Link>
        )}
      </div>

      <div className={styles.linkedAccounts}>
        <span className={styles.linkedAccountsTitle}> Linked Accounts:</span>
        {item.salesforceId && <LinkedAccountItem type="brand" newsItem={item} />}
        {item.salesforceId && item.referralSourceId && ', '}
        {item.referralSourceId && <LinkedAccountItem type="referral" newsItem={item} />}
      </div>
    </Card>
  )
}

export default MarketNewsDetails
